import { Component, OnInit ,Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  url:SafeUrl;
  constructor(@Inject(MAT_DIALOG_DATA) public data: {url: string},public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
   this.url = this.sanitizer.bypassSecurityTrustUrl(this.data.url);
  }

}
